/*
 * 업무구분: 영업
 * 화 면 명: MSPFS312M
 * 화면설명: 환매신청내역조회2505
 * 작 성 일: 2023.01.16
 * 작 성 자: 이지수
 */
<template>
  <ur-page-container class="fts" :show-title="false" :title="pHeaderObj.title" :topButton="true">
    <!-- alert modal popup -->
    <fs-alert-popup
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></fs-alert-popup>
     <!-- header start -->
    <fs-header
      ref="fsHeader"
      :propObj="pHeaderObj"
    ></fs-header>
    <!-- header end -->

    <!-- .fts-main -->
    <main class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top gap40">
          <div class="left column mr0">
            <div class="row w100p gap40">
              <div class="wrap-input row">
                <label class="emphasis"> 플라자구분 </label>
                <mo-radio-wrapper :items="Rad_dept_gb" v-model="dept_gb" class="row"/>
              </div>
            </div>
            <div class="row w100p gap40">
              <div class="wrap-input row">
                <label> 환매기준 </label>
                <mo-dropdown :items="Cob_redem_req_gb" v-model="redem_req_gb" class="w130" placeholder="선택하세요" />
              </div>
            </div>
            <div class="row w100p">
              <div class="wrap-input row">
                <label class="emphasis"> 기준일 </label>
                <mo-date-picker
                  ref="calFrom"
                  v-model="cal_from"
                  :bottom="false"
                  class="w110"
                  :class="cal_from_error ? 'error' : ''"
                  no-placeholder
                  @input="fn_dateCheck"
                  /> <!-- 시작일 -->
                <i> ~ </i>
                <mo-date-picker
                  ref="refTo"
                  v-model="cal_To"
                  :bottom="false"
                  class="w110"
                  :class="cal_To_error ? 'error' : ''"
                  no-placeholder
                  @input="fn_dateCheck"
                  /> <!-- 종료일 -->
                <!-- [D] 기준 시작일과 종료일 입력 시, 시작일보다 종료일이 작을 때나 종료일보다 시작일이 클 때는 알림(토스트팝업) 후 초기값(오늘날짜) 작성 -->
                <!-- <mo-range-picker v-model="cal_fromTo" :bottom="false" class="w130" /> -->
              </div>
            </div>
            <div class="row w100p">
              <div class="wrap-input row">
                <label class="emphasis"> 구분 </label>
                <mo-radio-wrapper :items="Rad_trust_gb" v-model="trust_gb" class="row"/>
              </div>
            </div>
          </div>
          <div class="right column gap10 w50p">
            <div class="row w100p">
              <div class="wrap-input row">
                <label class="emphasis"> 영업일구분 </label>
                <mo-radio-wrapper :items="Rad_trd_gb" v-model="trd_gb" class="row"/>
              </div>
            </div>
            <div class="row w100p">
              <div class="wrap-input row">
                <label class="emphasis"> 플라자코드 </label>
                <mo-text-field
                  v-model="dept_cd"
                  @input="fn_searchPlaza"
                  :disabled="dept_cd_disabled"
                  ref="deptCd"
                  maxlength="3"
                  type="number"
                  :class="dept_cd_error ? 'error' : ''"
                  class="w130"
                />
                <mo-button @click="fn_OpenPopup(0)" :disabled="btn_save1"> 플라자 </mo-button>
                <mo-text-field v-model="dept_nm" class="w150" disabled/>
              </div>
            </div>
            <div class="row w100p gap40">
              <div class="wrap-input row">
                <label> 펀드유형 </label>
                <mo-dropdown :items="lv_commCode.code107" v-model="fund_tp3" class="w150" placeholder="전체" />
                <!-- <mo-text-field v-model="text03" class="mw100" /> -->
              </div>
              <div class="wrap-input row">
                <label> 유선여부 </label>
                <mo-dropdown :items="Cob_tel_use_yn" v-model="tel_use_yn" class="w100" placeholder="전체" disabled />
              </div>
            </div>
            <div class="row w100p justify-between">
              <div class="wrap-input row">
                <label> 매체구분 </label>
                <mo-dropdown :items="Cob_media_gb" v-model="media_gb" class="w150" placeholder="전체" />
                <!-- <mo-text-field v-model="text04" class="mw100" /> -->
              </div>
              <div class="wrap-button row">
                <mo-button class="clear" @click="fn_init"> 초기화 </mo-button>
                <mo-button primary class="inquiry" @click="fn_searchList"> 조회 </mo-button>
              </div>
            </div>
          </div>
        </div>
      </ur-box-container>
      <ur-box-container direction="column" alignV="start">
        <div class="right-bg-white mt16">
          <div class="wrap-table x-scroll h-scroll">
            <table class="table row-type wsN">
              <thead>
                <tr>
                  <th> LT </th>
                  <th> 고객명 </th>
                  <th> 계좌번호 </th>
                  <th> 펀드명 </th>
                  <th> 신청일 </th>
                  <th> 신청좌수 </th>
                  <th> 신청금액 </th>
                  <th> 결제좌수 </th>
                  <th> 환매금액 </th>
                  <th> 체결일 </th>
                  <th> 결제일 </th>
                  <th> 신청점 </th>
                  <th> 관리점 </th>
                </tr>
                <!-- bind:late_trading_yn,
                     bind:cust_nm,
                     bind:acct_no,
                     bind:fund_nm,
                     bind:trd_dt yyyy-mm-dd,
                     bind:req_qty
                     bind:req_amt
                     bind:setl_qty
                     bind:setl_amt
                     bind:fix_dt
                     bind:setl_dt
                     bind:req_dept_nm
                     bind:mgt_br_nm
                 -->
              </thead>
              <tbody v-if="ds_list.length>0">
                <tr v-for="(row, i) in ds_list" :key="i">
                  <td> {{row.late_trading_yn}} </td>
                  <td> {{row.cust_nm}} </td>
                  <td> {{fn_acctNoDashFormat(row.acct_no)}} </td>
                  <td> {{row.fund_nm}} </td>
                  <td> {{$bizUtil.dateDashFormat(row.trd_dt)}} </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(row.req_qty)}} </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(row.req_amt)}} </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(row.setl_qty)}} </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(row.setl_amt)}} </td>
                  <td> {{$bizUtil.dateDashFormat(row.fix_dt)}} </td>
                  <td> {{$bizUtil.dateDashFormat(row.setl_dt)}} </td>
                  <td> {{row.req_dept_nm}} </td>
                  <td> {{row.mgt_br_nm}} </td>
                </tr>
              </tbody>
              <tfoot v-if="ds_list.length > 0">
                <tr>
                  <td>합계</td>
                  <td></td>
                  <td>{{sum_acct_no}}건</td>
                  <td></td>
                  <td></td>
                  <td class="aR">{{$bizUtil.numberWithCommas(req_qty_sum)}}</td>
                  <td class="aR">{{$bizUtil.numberWithCommas(req_amt_sum)}}</td>
                  <td class="aR">{{$bizUtil.numberWithCommas(setl_qty_sum)}}</td>
                  <td class="aR">{{$bizUtil.numberWithCommas(setl_amt_sum)}}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </ur-box-container>

    </main>
    <!-- //.fts-main -->
    <!-- popup311 -->
    <msp-fs-311p
      ref="popup311"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup311Obj"
    ></msp-fs-311p>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역
***********************************************************************************/
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
import FSHeader from '~/src/ui/fs/comm/FSHeader' // header 영역 (공통)
import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup' // Alert 팝업 (공통)
import moment from 'moment'

import MSPFS311P from '~/src/ui/fs/MSPFS311P'

export default {
  /***********************************************************************************
     * Vue 파일 속성 정의 영역
  ***********************************************************************************/
  name: 'MSPFS312M',
  screenId: 'MSPFS312M',
  components: {
    'fs-header': FSHeader,
    'fs-alert-popup': FSAlertPopup,
    'msp-fs-311p': MSPFS311P
  },
  /***********************************************************************************
     * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created () {
    this.fn_SetCommCode()
  },
  mounted () {
    this.$bizUtil.insSrnLog(this.$options.screenId)

    // 파라미터가 있는 경우
    if ( !FSCommUtil.gfn_isNull(this.$route.params) ) {
      setTimeout(() => {
        // this.lv_cob_rname_gb.value = this.$route.params.cob_rname_gb
        // this.lv_ed_rname_no.value1 = this.$route.params.ed_rname_no[0]
        // this.lv_ed_rname_no.value2 = this.$route.params.ed_rname_no[1]
        console.log('파라미터있음 $routeParm===', this.$route.params)
      }, 100)
    }
    this.fn_init()
  },
  /***********************************************************************************
     * 화면변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      dept_gb: '14', // 플라자구분
      redem_req_gb: '0', // 환매기준
      // cal_fromTo : [],//기준일
      cal_from: '', // 기준일from
      cal_To: '', // 기준일TO
      trust_gb: 'G', // 구분
      trd_gb: '11', // 영업일구분
      dept_cd: '', // 플라자코드
      dept_nm: '', // 플라자명
      fund_tp3: 'ALL', // 펀드유형
      tel_use_yn: 'A', // 유선여부
      media_gb: '', // 매체구분

      req_amt_sum: 0, // 신청금액 합계
      setl_qty_sum: 0, // 결제좌수 합계
      req_qty_sum: 0,
      setl_amt_sum: 0,
      ds_list: [
        // {
        //   acct_no : '',
        //   trd_no : '',
        //   trd_dt : '',
        //   req_dept_nm : '',
        //   cust_nm : '',
        //   fund_nm : '',
        //   req_qty : '',
        //   setl_amt : '',
        //   inc_tax : '',
        //   resi_tax : '',
        //   redem_gb : '',
        //   proc_gb : '',
        //   fix_dt : '',
        //   setl_dt : '',
        //   late_trading_yn : '', // LT
        //   match_err_cd : '',
        //   redem_fee : '',
        //   fix_qty : '',
        //   inv_empno : '',
        //   inv_dept_cd : '',
        //   media_gb : '',
        //   cms_gb : '',
        //   depo_fund_cd : '',
        //   fund_cd : '',
        //   setl_qty : '',
        //   req_amt : '',
        //   recv_fee : '',
        //   emp_nm : '',
        //   branch_cd : '',
        //   jijum_cd : '',
        //   saupbu_cd : '',
        //   mgt_br_nm : '',
        //   fund_tp3 : '',
        //   upp_dept_cdnm : '',
        //   sel_req_recp_dt : '',
        //   sel_req_recp_trd_no : '',
        //   trd_bank_nm : '',
        //   btrans_acct_no : '',
        //   cdsc_conv_group : '',
        //   cdsc_class_gb : '',
        //   cell_no : '',
        //   proc_tm : '',
        //   proc_emp : '',
        //   tel_use_yn : '',
        //   redem_mmf_buy_yn : '',
        //   outcome_rewd : '',
        // }
      ],

      sum_acct_no: '', // 계좌 총 건수
      sum_req_qty: '', // 신청좌수 합계
      sum_req_amt: '', // 신청금액 합계
      sum_setl_qty: '', // 결제좌수 합계
      sum_setl_amt: '', // 환매금액 합계

      // disabled
      dept_cd_disabled: false, // 플라자코드 disabled
      btn_save1: false,
      // error
      dept_cd_error: false, // 플라자코드 error
      cal_from_error: false, // 기준일 from
      cal_To_error: false, // 기준일 to
      // as-is 전역변수
      gFundSearch: '9999',
      gFundAllSearch: '',
      fndCdLen: '', // this.gfn_userinfo("fndCdLen");
      usid: '', // this.gfn_userinfo("usid");
      brcd: '', // this.gfn_userinfo("brcd");
      brNm: '', // = this.gfn_userinfo("brNm");
      disp_fof_cd: '', // this.gfn_userinfo("disp_fof_cd");
      disp_fof_nm: '', // = this.gfn_userinfo("disp_fof_nm");
      hdshp_yn: '', // this.gfn_userinfo("hdshp_yn");

      /***********************************************************************************
         * 공통코드
      ************************************************************************************/
      lv_commCode: {}, // 펀드유형-107, 임직원화면권한-975
      // EAI(PO) 호출 객체
      eaiCommObj: FSCommUtil.gfn_eaiCommObj(),
      lv_basInfo: this.getStore('fsStore').getters.getBasInfo.data,
      // 공통 객체
      pHeaderObj: {
        title: '환매신청내역조회'
      },
      // alert
      pAlertPopupObj: {},
      pPopup311Obj: {}

    }
  },
  /***********************************************************************************
     * Computed 함수 정의 영역
  ***********************************************************************************/
  computed: {
    // 플라자구분
    Rad_dept_gb () {
      let rtn = []
      rtn.push({value: '14', text: '신청점'})
      rtn.push({value: '11', text: '관리점'})
      return rtn
    },
    // 구분
    Rad_trust_gb () {
      let rtn = []
      rtn.push({value: 'G', text: '일반'})
      rtn.push({value: 'T', text: '전체(신탁포함)'})
      return rtn
    },
    // 영업일구분
    Rad_trd_gb () {
      let rtn = []
      rtn.push({value: '11', text: '신청일'})
      rtn.push({value: '13', text: '체결일'})
      rtn.push({value: '12', text: '결제일'})
      return rtn
    },
    // 환매기준
    Cob_redem_req_gb () {
      let rtn = []
      rtn.push({value: '0', text: '전체'})
      rtn.push({value: '1', text: '일반'})
      rtn.push({value: '2', text: '엄브렐러전환'})
      rtn.push({value: '3', text: '자동대체'})
      rtn.push({value: '4', text: '이동출고'})
      rtn.push({value: '5', text: '정기지급형'})
      rtn.push({value: '6', text: '연금지급'})
      rtn.push({value: '7', text: '정기이체'})
      rtn.push({value: '8', text: '연금중도인출'})
      rtn.push({value: '9', text: '연금타사이전'})
      rtn.push({value: '10', text: '신연금전환'})
      return rtn
    },
    // 유선여부
    Cob_tel_use_yn () {
      let rtn = []
      rtn.push({value: 'A', text: '전체'})
      rtn.push({value: 'N', text: '일반'})
      rtn.push({value: 'Y', text: '유선'})
      rtn.push({value: 'E', text: '기타'})
      return rtn
    },
    // 매체구분
    Cob_media_gb () {
      let rtn = []
      rtn.push({value: '0', text: '전체'})
      rtn.push({value: '1', text: '영업점'})
      rtn.push({value: '2', text: '인터넷'})
      rtn.push({value: '4', text: '모바일'})
      rtn.push({value: '5', text: '태블릿내방'})
      rtn.push({value: '6', text: '태블릿방판'})
      return rtn
    }
  },
  /***********************************************************************************
     * watch 함수 정의 영역
  ***********************************************************************************/
  watch: {

  },
  /***********************************************************************************
     * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /******************************************************************************
       * Function명 : fn_historyBack
       * 설명       : 뒤로가기 버튼
    ******************************************************************************/
    fn_HistoryBack () {
      this.$router.go(-1)
    },
    /******************************************************************************
       * Function명 : fn_SetCommCode, fn_SetCommCodeResult
       * 설명       : 공통코드 세팅
    ******************************************************************************/
    fn_init () {
      this.fndCdLen = this.lv_basInfo.fndCdLen// this.gfn_userinfo("fndCdLen");
      this.usid = this.lv_basInfo.usid // this.gfn_userinfo("usid");
      this.brcd = this.lv_basInfo.brcd // this.gfn_userinfo("brcd");
      this.brNm = this.lv_basInfo.brNm // = this.gfn_userinfo("brNm");
      this.disp_fof_cd = this.lv_basInfo.disp_fof_cd // this.gfn_userinfo("disp_fof_cd");
      this.disp_fof_nm = this.lv_basInfo.disp_fof_nm // = this.gfn_userinfo("disp_fof_nm");
      this.hdshp_yn = this.lv_basInfo.hdshp_yn // this.gfn_userinfo("hdshp_yn");

      this.dept_gb = '14' // 플라자구분
      this.redem_req_gb = '0' // 환매기준

      this.trust_gb = 'G' // 구분
      this.trd_gb = '11' // 영업일구분
      this.dept_cd = '' // 플라자코드
      this.dept_nm = '' // 플라자명
      this.fund_tp3 = 'ALL' // 펀드유형
      this.tel_use_yn = 'A' // 유선여부
      this.media_gb = '' // 매체구분

      this.req_amt_sum = 0
      this.setl_qty_sum = 0
      this.req_qty_sum = 0
      this.setl_amt_sum = 0

      this.ds_list = []

      this.dept_cd = this.disp_fof_cd
      this.dept_nm = this.disp_fof_nm

      // 부지점 disabled
      this.dept_cd_disabled = false
      this.btn_save1 = false
      this.dept_cd_error = false

      this.sum_acct_no = '' // 계좌 총 건수
      this.sum_req_qty = '' // 신청좌수 합계
      this.sum_req_amt = '' // 신청금액 합계
      this.sum_setl_qty = '' // 결제좌수 합계
      this.sum_setl_amt = '' // 환매금액 합계

      if (this.fndCdLen === '4') {
        this.gFundAllSearch = '9999'
      } else if (this.fndCdLen === '5') {
        this.gFundAllSearch = '99999'
      } else if (this.fndCdLen === '6') {
        this.gFundAllSearch = '999999'
      }

      if (this.brcd !== '905') {
        // test
        this.dept_cd_disabled = true
        this.btn_save1 = true
      }

      this.cal_To = FSCommUtil.gfn_dateReplace(1, FSCommUtil.gfn_strToday(1))
      this.cal_from = FSCommUtil.gfn_dateReplace(1, FSCommUtil.gfn_strToday(1))
    },

    /******************************************************************************
       * Function명 : fn_searchList
       * 설명       : 조회
    ******************************************************************************/
    fn_searchList () {
      if (!this.fn_validateForm()) {
        return
      }

      // 서비스 호출
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'

      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570220_S'
      this.eaiCommObj.params = {
        proc_gb: 'S',
        dept_gb: this.dept_gb,
        dept_cd: this.dept_cd,
        biz_dt_gb: this.trd_gb,
        media_gb: this.media_gb,
        redem_req_gb: this.redem_req_gb,
        fund_cd: '', // 펀드코드, Length : 12
        fund_tp3: this.fund_tp3,
        fromstd_dt: moment(this.cal_from).format('YYYYMMDD'),
        tostd_dt: moment(this.cal_To).format('YYYYMMDD'),
        cdsc_conv_group: '', // this.cdsc_conv_group,
        tel_use_yn: this.tel_use_yn,
        vip_gb: this.trust_gb,
        cust_no: '',
        inv_empno: '',
        rad_person_gb: ''
      }

      this.ds_list = []
      this.req_amt_sum = 0
      this.setl_qty_sum = 0
      this.req_qty_sum = 0
      this.setl_amt_sum = 0

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)
    },

    /******************************************************************************
       * Function명 : fn_callBack
       * 설명       : 조회
    ******************************************************************************/
    fn_callBack (response) {
      console.log('환매조회 response ===', response)
      var data = response.data.rp250500_O_00VO
      if (FSCommUtil.gfn_length(data)) {
        this.ds_list = data
        // 합계 구하기
        this.ds_list.forEach((item, idx) => {
          this.req_amt_sum = Number(item.req_amt) + this.req_amt_sum
          this.setl_qty_sum = Number(item.setl_qty) + this.setl_qty_sum
          this.req_qty_sum = Number(item.req_qty) + this.req_qty_sum
          this.setl_amt_sum = Number(item.setl_amt) + this.setl_amt_sum
        })
        // 총 건수
        this.sum_acct_no = data.length
      } else {
        console.log('결과없음 ')
      }
    },

    /***********************************************************************************
      * Function명 : fn_searchPlaza
      * 설명       : 영업점 조회
    ***********************************************************************************/
    fn_searchPlaza () {
      this.dept_cd_error = false
      if (FSCommUtil.gfn_length(this.dept_cd) < 3) {
        this.dept_nm = ''
        return
      }
      // 서비스 호출
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'

      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570271_S'
      this.eaiCommObj.params = {
        bfcer_fof_cd: this.dept_cd,
        bfcer_fof_nm: ''
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBackPlaza)
    },

    fn_callBackPlaza (response) {
      console.log('fn_callBackplaza', response)
      var data = response.data.sy000400_O_00VO[0]
      console.log('부점 조회 data ', data )
      if (!FSCommUtil.gfn_isNull(data) || data !== undefined) {
        this.dept_cd = data.bfcer_fof_cd
        this.dept_nm = data.bfcer_fof_nm
      } else {
        FSCommUtil.gfn_validate(this, '부점이 존재하지 않습니다.')
        this.dept_cd = ''
        this.dept_nm = ''
      }
    },

    /******************************************************************************
       * Function명 : fn_validateForm
       * 설명       : 유효성검사
    ******************************************************************************/
    fn_validateForm () {
      var fromDate = moment(this.cal_from).format('YYYYMMDD')
      var toDate = moment(this.cal_To).format('YYYYMMDD')

      var vMaxMm = moment(fromDate).add(1, 'months').format('YYYYMMDD')
      let t_popupObj = {}

      if (vMaxMm < toDate) {
        FSCommUtil.gfn_validate(this, '조회기간은 최대 1개월까지 가능합니다.')
        this.cal_from_error = true
        this.cal_To_error = true
        this.$refs['calFrom'].focus()
        this.fn_AlertPopup(0, t_popupObj)
        return false
      }

      if (FSCommUtil.gfn_length(this.dept_cd) !== 3 ||
         FSCommUtil.gfn_length(this.dept_cd) === 0 ||
         FSCommUtil.gfn_isNull(this.dept_nm)) {
        FSCommUtil.gfn_validate(this, '플라자코드를 입력하십시오.')
        this.dept_cd_error = true
        this.$refs['deptCd'].focus()
        return false
      }

      if (fromDate > toDate) {
        FSCommUtil.gfn_validate(this, '시작일이 큰수입니다. 조회일을 확인하여 주세요')
        this.cal_from_error = true
        this.$refs['calFrom'].focus()
        return false
      }
      return true
    },

    /******************************************************************************
       * Function명 : fn_Popup_CallBack
       * 설명       : popup 컴포넌트에서 callback
       * 타입(type) : 0: 우편번호 / 1: 업종코드
    ******************************************************************************/
    fn_Popup_CallBack (type, pData) {
      switch (type) {
        case 0:
          console.log('popup callback...', JSON.stringify(pData))
          if (pData.bfcer_fof_cd !== undefined && pData.bfcer_fof_cd !== '') {
            this.dept_cd = pData.bfcer_fof_cd
            this.dept_nm = pData.bfcer_fof_nm
          }
          break
      }
    },

    /******************************************************************************
       * Function명 : fn_dateCheck
       * 설명       : from ~ to 날짜 변경 시
    ******************************************************************************/
    fn_dateCheck () {
      if (this.cal_from.replace(/-/g, '') > this.cal_To.replace(/-/g, '')) {
        this.cal_To = FSCommUtil.gfn_dateReplace(1, FSCommUtil.gfn_strToday(1))
        this.cal_from = FSCommUtil.gfn_dateReplace(1, FSCommUtil.gfn_strToday(1))
        console.log('날짜 변경 후 ', this.cal_from)
        console.log('날짜 변경 후 ', this.cal_To)
        FSCommUtil.gfn_validate(this, '날짜 형식에 맞지 않습니다.')
      }
    },

    /******************************************************************************
       * Function명 : fn_acctNoDashFormat
       * 설명       : 계좌번호 처리
    ******************************************************************************/
    fn_acctNoDashFormat (acctNo) {
      // acctNo = FSCommUtil.gfn_substr(acctNo, 0, 7) + '-' + FSCommUtil.gfn_substr(acctNo, 7, 3) + '-' + FSCommUtil.gfn_substr(acctNo, 10, 3)
      acctNo = FSCommUtil.gfn_substr(acctNo, 0, 3)  + '****-*' + FSCommUtil.gfn_substr(acctNo, 8, 2) + '-' + FSCommUtil.gfn_substr(acctNo, 10, 3)
      return acctNo
    },

    /******************************************************************************
       * Function명 : fn_OpenPopup
       * 설명       : popup 컴포넌트 호출
       * 타입(type) :
    ******************************************************************************/
    fn_OpenPopup (type) {
      switch (type) {
        case 0:
          this.$refs.popup311.fn_Open()
          break
      }
    },

    /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
    ******************************************************************************/
    fn_AlertPopup (type, pPopupObj) {
      if ( !FSCommUtil.gfn_isNull(pPopupObj) ) {
        this.pAlertPopupObj.content = pPopupObj.content
        this.pAlertPopupObj.confirm = false
        this.pAlertPopupObj.cancel = true

        if ( !pPopupObj.cancel ) {
          this.pAlertPopupObj.cancel = pPopupObj.cancel
        }
        if ( pPopupObj.confirm ) {
          this.pAlertPopupObj.confirm = pPopupObj.confirm
          this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
          this.pAlertPopupObj.confirmData = pPopupObj.confirmData
        }
        if ( pPopupObj.closeFunc ) {
          this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc
          this.pAlertPopupObj.closeData = pPopupObj.closeData
        }
      }
      this.$refs.alertPopup.fn_Open()
    },

    /******************************************************************************
       * Function명 : fn_SetCommCode, fn_SetCommCodeResult
       * 설명       : 공통코드 세팅
    ******************************************************************************/
    fn_SetCommCode () {
      console.log('fn_SetCommCode...')

      let params = [
        {'cId': '107', 'dayChkYn': 'N'},
        {'cId': '975', 'dayChkYn': 'N'}
      ]
      FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
    },

    fn_SetCommCodeResult (pResult) {
      console.log('공통코드107 result', pResult)
      this.lv_commCode = pResult

      let t_codeList = []

      pResult.code107.forEach((item, idx) => {
        if (idx === 0) {
          t_codeList.push({
            text: '전체',
            value: 'ALL'
          })
          t_codeList.push(item)
        } else {
          t_codeList.push(item)
        }
      })

      this.lv_commCode.code107 = t_codeList
      this.lv_commCode = pResult
    }
  }// methods end

}
</script>
<style scoped>
</style>
